@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);

.multi-email-wrapper .react-multi-email {
  max-width: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  font-size: 16px;
  background-color: #fff;
  transition: all 0.2s;
}
.multi-email-wrapper .react-multi-email > span[data-placeholder] {
  display: none;
  padding: 0 10px;
  color: hsl(225, 4%, 56%);
}

.multi-email-wrapper .react-multi-email.focused {
  /* !important because default style is set as an inline style. */
  border-color: hsl(209, 100%, 50%) !important; /* blue.500 */
  box-shadow: 0 0 0 2px hsla(209, 100%, 50%, 0.3);
}

.multi-email-wrapper .react-multi-email.empty > span[data-placeholder] {
  display: inline-block;
}
.multi-email-wrapper .react-multi-email.focused > span[data-placeholder] {
  display: none;
}

.multi-email-wrapper .react-multi-email > input {
  width: auto !important;
  outline: none !important;
  border: 0 none !important;
  padding: 4px 16px !important;
  font-size: 16px;
}

.multi-email-wrapper .react-multi-email [data-tag] {
  margin: 4px;
  background-color: hsl(209, 100%, 95%);
  padding: 4px 4px 4px 12px;
  border-radius: 1.25em;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  line-height: 1.5;
}

.multi-email-wrapper .react-multi-email [data-tag] [data-tag-item] {
  max-width: 100%;
  overflow: hidden;
}
.multi-email-wrapper .react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 0.833em;
  cursor: pointer;
}

